import React, {useEffect, useState} from "react";
import {getRandomList} from "../repository/MetaInfoRepository";


export default function MetaChipList(props) {
    const [selectedList, setSelectedList] = useState([])
    const [metaList, setMetaList] = useState([]);

    const handleMetaClick = (value) => {
        setSelectedList(prev => {
            if (prev.includes(value)) {
                return prev.filter(item => item !== value);
            } else {
                return [...prev, value];
            }
        });
    };

    const handleOnSearch = () => {
        if (selectedList.length !== 0) {
            props.setMetaList(selectedList)
        }
    }

    useEffect(() => {
        getRandomList().then(r => {
            r && setMetaList(
                r['data']['meta_name_list']
            )
        })
    }, []);

    return (
        <>
            <div className='ontology-checkbox-container'>
                {metaList.map(value => (
                    <label
                        className={`ontology-checkbox ${selectedList.includes(value) ? 'selected' : ''}`}
                        key={value}
                        onClick={() => handleMetaClick(value)}
                    >
                        <input
                            type="checkbox"
                            name="ontology"
                            value={value}
                            checked={selectedList.includes(value)}
                            onChange={() => handleMetaClick(value)}
                        />
                        <div className="detail-meta-value-container">{value}</div>
                    </label>
                ))}
            </div>
            <div className="button-container">
                <button
                    type="button"
                    className="detail-submit-button recommend-button"
                    onClick={handleOnSearch}
                >
                    Recommended Movies
                </button>
            </div>
        </>
    )
}