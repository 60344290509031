import React, {useState} from 'react';

import '../assets/css/vod-main02.css';
import '../assets/css/vod-detail02.css';
import MetaChipList from "../container/MetaChipList";
import MovieListByMeta from "../container/MovieListByMeta";

const Home = () => {
    const [metaList, setMetaList] = useState([])

    return (
        <div>
            <header>
                <a className="header-title" href="/">
                    VOD Curation Algorithm
                </a>
            </header>
            <section className="main">
                <div className="meta-container">
                    <div className='meta-detail-content-container'>
                        <div className="meta-container-title">
                            O2O's metadata generator based content recommendation system
                        </div>
                        <MetaChipList setMetaList={setMetaList}/>
                    </div>
                </div>

                <MovieListByMeta metaList={metaList}/>


                {/*<div className="meta-form">*/}
                {/*    {curationList.map((curation, index) => (*/}
                {/*        <div className="recommend-item-container" key={index}>*/}
                {/*            <div className="attribute-name-container">*/}
                {/*                <strong>{curation.curation_title}</strong>*/}
                {/*            </div>*/}
                {/*            <div className="recommend-attribute-container" id={curation.curation_title}>*/}
                {/*                {moviesInfo[curation.curation_title] && moviesInfo[curation.curation_title].map(movieInfo => (*/}
                {/*                    <div className="recommend-content-container" key={movieInfo.movie_id} onClick={handleRecommendItemClick}>*/}
                {/*                        <img src={movieInfo.movie_img} alt={movieInfo.movie_name} id={movieInfo.movie_id_each} />*/}
                {/*                        <div className="item-footer-container">*/}
                {/*                            <div className="movie-name">{movieInfo.movie_name}</div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                ))}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    ))}*/}
                {/*</div>*/}
            </section>
        </div>
    );
};

export default Home;
