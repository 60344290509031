import {useEffect, useState} from "react";
import {getMovieListByMetaList} from "../repository/MovieInfoRepository";
import {useNavigate} from "react-router-dom";
import {decodeURL} from "../infra/UrlEncoder";


export function MovieCard(props) {
    const {movie} = props
    const [imageLoaded, setImageLoaded] = useState(true); // 이미지 로드 상태 관리
    const navigate = useNavigate();

    if (!movie) return null
    if (!imageLoaded) return null

    const onClickMovie = () => {
        navigate(`/movie/${movie.movie_id}`);
    }

    const handleImageError = () => {
        setImageLoaded(false); // 이미지 로드 실패 시 렌더링 중지
    };
    return (
        <div className="recommend-content-container" key={movie.movie_id}
             onClick={onClickMovie}>
            <img src={decodeURL(movie)} alt={movie.movie_name}
                 id={movie.movie_id}
                 onError={handleImageError}
            />
            <div className="item-footer-container">
                <div className="movie-name">{movie.title}</div>
            </div>
        </div>
    )
}


export default function MovieListByMeta(props) {
    const [movies, setMovies] = useState([])

    useEffect(() => {
        if (props.metaList.length !== 0) {
            getMovieListByMetaList(props.metaList).then(r => {
                r && setMovies(r['data']['movies'])
            })
        }
    }, [props.metaList]);


    return (
        <div className="meta-form">
            <div className="recommend-item-container">
                <div className="attribute-name-container">
                    <strong>{props.metaList.join(' # ')}</strong>
                </div>
                <div className="recommend-attribute-container" id={"selected_card_list"}>
                    {
                        movies.map(movie => {
                            return <MovieCard key={movie.movie_id} movie={movie}/>
                        })
                    }
                </div>
            </div>
        </div>
    )
}