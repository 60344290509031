import apiClient from "../infra/ApiClient";

const lang = process.env.REACT_APP_LANG
const uid = process.env.REACT_APP_UNDER_ID


export async function getMovieListByMetaList(metaList) {
    try {

        const queryString = metaList.map(meta => `meta=${encodeURIComponent(meta)}`).join('&');
        let reqUrl = `/api/v1/movie/meta_list?page=1&lang=${lang}&page_size=10&${queryString}`
        if (uid) {
            reqUrl += `&uid=${uid}`
        }

        const response = await apiClient.get(reqUrl);
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}


export async function getMovieById(movieId) {
    try {
        let reqUrl = `/api/v1/movie/${movieId}?lang=${lang}`
        if (uid) {
            reqUrl += `&uid=${uid}`
        }
        const response = await apiClient.get(reqUrl);
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function getMovieByVector(movieId) {
    try {
        // 일단은 아직 백엔드 기능 구현 x
        let reqUrl = `/api/v1/movie/${movieId}/recommendations?lang=${lang}`
        if (uid) {
            reqUrl += `&uid=${uid}`
        }
        const response = await apiClient.get(reqUrl);
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}