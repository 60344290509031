export function decodeURL(movie) {
    const src = movie.image_url


    if (!src) {
        return '';
    }

    // https로 시작하면 그대로 반환
    if (src.startsWith('https')) {
        return src;
    }
    return `https://image.tmdb.org/t/p/w500/${src}`
}