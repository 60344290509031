import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getMovieById, getMovieByVector} from "../repository/MovieInfoRepository";
import {decodeURL} from "../infra/UrlEncoder";


export default function MovieDetail(props) {
    const {movie_id} = useParams();
    const navigate = useNavigate()
    const [movie, setMovie] = useState(null)

    useEffect(() => {
        if (!movie_id) {
            navigate(`/`);
        }
        getMovieById(
            movie_id
        ).then(r => {
            if (!r) {
                navigate(`/`);
            }
            setMovie(r.data)
        })

    }, [movie_id]);

    return (
        <>
            <header>
                <a className="header-title" href="/">
                    VOD Curation Algorithm
                </a>
            </header>

            <div className={'main'}>
                <MovieDetailPanel movie={movie}/>
                <MovieRecommend movie={movie}/>
            </div>

        </>
    )
}


function MovieDetailPanel(props) {
    const {movie} = props
    if (!movie) {
        return null
    }

    const directors = movie.director.split(",").map(name => name.trim()).filter(name => name !== "");
    const actors = movie.actors.split(",").map(name => name.trim()).filter(name => name !== "");
    return (
        <div className='detail-thumbnail-container'>
            <div className='detail-img-container'>
                <img src={decodeURL(movie)} alt={movie.title}/>
            </div>
            <div className='movie-detail-content-container'>
                <div className='movie-detail-title'>
                    {movie.title}
                </div>

                <div className='detail-attribute-container'>
                    <div className='detail-genre-container'>
                        <div className='detail-attribute-title'>Attributes</div>
                        <div className='genre-checkbox-container'>
                            {
                                movie.metas.map((meta) => {
                                    return (
                                        <label className="genre-checkbox">
                                            <input type="checkbox" name="genre" value={meta}/>
                                            <div className="detail-genre-value-container">{meta}</div>
                                        </label>
                                    )
                                })
                            }
                        </div>
                    </div>


                    <div className='detail-genre-container'>
                        <div className='detail-attribute-title'>Genres</div>
                        <div className='genre-checkbox-container'>
                            <label className="genre-checkbox">
                                <input type="checkbox" name="genre" value={movie.genre}/>
                                <div className="detail-genre-value-container">{movie.genre}</div>
                            </label>
                        </div>
                    </div>

                    <div className='detail-director-container'>
                        <div className='detail-attribute-title'>Director</div>
                        <div className='director-checkbox-container'>
                            {
                                directors.map((director) => {
                                    return (
                                        <label className="director-checkbox">
                                            <input type="checkbox" name="director" value={director}/>
                                            <div className="detail-director-value-container">{director}</div>
                                        </label>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='detail-actor-container'>
                        <div className='detail-attribute-title'>Actors</div>
                        <div className='actor-checkbox-container'>
                            {
                                actors.map((actor) => {
                                    return (
                                        <label className="actor-checkbox">
                                            <input type="checkbox" name="actor" value={actor}/>
                                            <div className="detail-actor-value-container">{actor}</div>
                                        </label>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='detail-actor-container'>
                        <div className='detail-attribute-title'>Plot</div>
                        <div className='actor-checkbox-container'>
                            <p>
                                {movie.description}
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

function MovieCard(props) {
    const {movie, index, handleClick, distance} = props
    const [imageLoaded, setImageLoaded] = useState(true); //
    if (!movie) return null
    if (!imageLoaded) return null

    const handleImageError = () => {
        setImageLoaded(false); // 이미지 로드 실패 시 렌더링 중지
    };
    return (
        <div key={index} className="recommend-content-container" onClick={() => handleClick(movie.movie_id)}>
            <img src={decodeURL(movie)}
                 id={movie.movie_id}
                 onError={handleImageError}
                 alt={movie.title}/>
            <div className="item-footer-container">
                <div className="movie-name">{movie.title}</div>
                <div className="movie-name">
                    Similarity {((1 - distance) * 100).toFixed(2)}%
                </div>
            </div>
        </div>
    )
}


function RecommendCardContainer(props) {
    const {rootMovie} = props
    const [similarMovies, setSimilarMovies] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        if (!rootMovie) {
            return
        }
        if (!rootMovie.movie_id) {
            return;
        }
        setSimilarMovies([])
        getMovieByVector(rootMovie.movie_id).then(r => {
            r && r['data'] && setSimilarMovies(r['data'])
        })

    }, [rootMovie]);


    if (!Array.isArray(similarMovies) || similarMovies.length === 0) {
        return <div>No similar movies found.</div>;
    }

    function handleClick(movieId) {
        navigate(`/movie/${movieId}`)
    }


    return similarMovies.map((data, index) => (
        <MovieCard index={index}
                   distance={data['distance']}
                   movie={data['entity']} handleClick={handleClick}/>
    ));
}


function MovieRecommend(props) {
    const {movie} = props
    if (!movie) {
        return null
    }

    return (
        <div className='recommend-container'>
            <div className='recommend-item-container'>
                {/* <div className="attribute-name-container"><strong>유사 영화</strong></div> */}
                <div className="attribute-name-container"><strong>Similar Movies</strong></div>
                <div className='recommend-attribute-container'>
                    <RecommendCardContainer rootMovie={movie}/>
                </div>
            </div>
        </div>
    )
}