import apiClient from "../infra/ApiClient";


const lang = process.env.REACT_APP_LANG
const uid = process.env.REACT_APP_UNDER_ID


export async function getRandomList() {


    try {
        let baseUrl = `/api/v1/meta/list?lang=${lang}`
        if (uid) {
            baseUrl += `&uid=${uid}`
        }
        const response = await apiClient.get(baseUrl);
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}